import { JOB_QTY_MAX } from "../../../../../Job/jobUtils";
import { DATASTORE_TABLE_VALUE_COLUMN_MAXIMUM_COUNT } from "../../../processActionStep/config/DataStore";
export const EXPLANATION_EN = {
    "explanation.accessUnauthorized": "Your permissions do not allow you to perform this action.",
    "explanation.approvalNotRequired": "Your approval is not required.",
    "explanation.cannotCopyEmptyPayload": "The payload contains an empty array and cannot be copied.",
    "explanation.cannotCopyUncopyableProcessActions": "The following actions cannot be copied: Fail, Input and Output.",
    "explanation.cannotCreateRoutingTraining": "Trainings cannot be assigned to routings.",
    "explanation.cannotDeleteRoleUsedByAUser": "Cannot delete role since it is currently used by a user.",
    "explanation.changelogBlank": "The changelog was left blank.",
    "explanation.cannotFindTreeFileToUpdate": "Cannot find the tree file to update.",
    "explanation.columnsCountHasExceededLimit": `Columns count has exceeded the limit ${DATASTORE_TABLE_VALUE_COLUMN_MAXIMUM_COUNT}.`,
    "explanation.couldNotCancelVersionNotApprovedOrOnHold": "It is not possible to cancel the version because it is not approved or on hold.",
    "explanation.couldNotCancelVersionAlreadyReleased": "It is not possible to cancel the version because it has already been published.",
    "explanation.couldNotGetErpBom": "An error occurred while retrieving the BOM from the ERP.",
    "explanation.couldNotGetErpSetting": "No ERP settings found in the database.",
    "explanation.couldNotHoldVersionNotApproved": "It is not possible to hold the version because it is not approved.",
    "explanation.couldNotHoldVersionAlreadyReleased": "It is not possible to hold the version because it has already been published.",
    "explanation.couldNotUpdateJobArchived": "It is not possible to update an archived job.",
    "explanation.databaseQueryFailed": "The database query failed.",
    "explanation.electronicSignatureConsentUnchecked": "You did not consent to the electronic signature agreement.",
    "explanation.erpQueryFailed": "The query sent to the ERP system failed.",
    "explanation.executionError": "Error during code execution.",
    "explanation.executionNotAssociatedToReworkId": "The execution is not associated with a failure ticket.",
    "explanation.informationIncorrectOrMissing": "Information is incorrect or missing.",
    "explanation.invalidBom": "Invalid BOM.",
    "explanation.invalidJobOrBom": "Invalid job or BOM.",
    "explanation.invalidPassword": "Invalid password.",
    "explanation.invalidProcessVersionStatusForTrainingRequirementUpdate": "The training requirement cannot be updated with the current process status.",
    "explanation.invalidUsercode": "Invalid usercode.",
    "explanation.invalidUsername": "Invalid username.",
    "explanation.invalidUsernameOrPassword": "Invalid username or password.",
    "explanation.invalidReleaseDate": "Invalid publication date.",
    "explanation.invalidRole": "The role name or parameters are invalid.",
    "explanation.invalidVariableIdentifierPrefixSys": "Cannot use the SYS_ prefix to identify a variable. This prefix is reserved for the system variable of the application.",
    "explanation.invalidVariableIdentifierAlphanumericUnderscore": "The variable identifier can only contain alphanumeric characters and underscores.",
    "explanation.itemStatusNotExistOrArchived": "Item status does not exist or is archived.",
    "explanation.jobProcessQtyMaxReach": `The maximum quantity of items in a job process is ${JOB_QTY_MAX}.`,
    "explanation.jobQtyMaxReach": `The maximum quantity of items in a job is ${JOB_QTY_MAX}.`,
    "explanation.jobAlreadyCreated": "The job has already been created.",
    "explanation.trainingAlreadyCreated": "The training has already been created.",
    "explanation.leastOnProcessVersionNotApprovedInRouting": "At least one routing process does not have an approved version.",
    "explanation.missingPassword": "Missing password.",
    "explanation.noAccessRightAvalableOrFileAlreadyArchived": "User does not have access rights to archive file or to modify an already archived file.",
    "explanation.noFolderHasBeenSelected": "No folder has been selected.",
    "explanation.noOrganizationSettingConfigured": "Organization settings are not configured in the app.",
    "explanation.noProcessApprovedOrReleaseInRouting": "This routing does not contain an approved or released process version.",
    "explanation.noProcessVersionApproved": "No process approved version is available.",
    "explanation.noProcessVersionReleased": "No process released version is available.",
    "explanation.notAllMandatoryApprovalGroups": "Not all required approval groups have been selected to create a new version.",
    "explanation.noRunnerAvailable": "An error occurred with the process execution system.",
    "explanation.numberOfVersionBlank": "Number of the version was left blank.",
    "explanation.passwordTemporaryDisabled": "Password temporarily disabled.",
    "explanation.selfApprovedProcessVersion": "Unable to approve a version you created.",
    "explanation.statusReworkIsArchived": "The failure ticket is archived.",
    "explanation.processVersionIsNotDraft": "The process version is not a draft.",
    "explanation.referenceIdBlank": "Reference ID was left blank.",
    "explanation.storeResultError": "Data collected during the execution of a process could not be be sent to the database.",
    "explanation.trainerNotQualified": "Trainer is untrained and/or not certified user or not part of a trainer group.",
    "explanation.unableCreateTrainingCertificate": "The selected process or previous versions do not have training requirements. It is therefore impossible to create a training certificate for a process that does not require training.",
    "explanation.unableCreateTrainingReadingCertificate": "The training requirement of the selected process is not Reading. It is therefore impossible to create a reading certificate for this training.",
    "explanation.unableCreateTrainingCertificateTrainerNotQualified": "Trainer is untrained and/or not certified user or not part of a trainer group.",
    "explanation.unableCreateTrainingCertificateTrainerIsTrainee": "Unable to create the certificate, the trainer and the trainee are the same user.",
    "explanation.unableCreateTrainingCertificateAuditorIsCertified": "Unable to create the certification, the auditor and the certified trainee are the same user.",
    "explanation.unableCreateTrainingCertification": "The selected process or previous versions do not have training requirements. It is therefore impossible to create a training certification for a process that does not require training.",
    "explanation.unableCreateTrainingReadingCertificateMissingTrainingCertificate": "A training certificate is required for this version.",
    "explanation.unableCreateTrainingCertificationAuditorNotQualified": "Auditor is untrained and/or not certified user or not part of a trainer group.",
    "explanation.unableSignTrainingCertificate": "The pending training was signed by a user different from the one assigned to the training certificate.",
    "explanation.unableSignTrainingCertification": "The pending certification training was signed by a user different from the one assigned to the training certification.",
    "explanation.unablePerformActionVersionNotCorrect": "Unable to perform your action, the selected process version is not correct for this type of action.",
    "explanation.unhandledError": "Unhandled error during code execution.",
    "explanation.unknownError": "Unknown error.",
    "explanation.userNotActive": "User is not active or does not exist.",
    "explanation.variableSameIdentifier": "Another variable has the same identifier.",
    "explanation.versionAlreadyApproved": "This version is already approved.",
    "explanation.versionAlreadyPendingApprovedOrWaitingReleased": "Another version is already pending approval or waiting to be released.",
    "explanation.versionAlreadyExists": "This version number already exists.",
};
