import { theme } from "@aos/react-components";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Paper, Table, TableBody, TableContainer, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import { useProcessEditorContext } from "../../../context";

import WorkInstructionsBomItems from "./BomItems/WorkInstructionsBomItems";
import WorkInstructionsBomHeader from "./Header/WorkInstructionsBomHeader";
import { WorkInstructionsBomReload } from "./reload";

const useStyles = makeStyles({
    bom: {
        backgroundColor: theme.palette.grey[100],
        height: "calc(100vh - 107px)", // Header(75px), margins(32px)
        minWidth: "160px",
        overflowX: "hidden",
        overflowY: "auto",
    },
});

function WorkInstructionsBom(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();
    const { bom } = useProcessEditorContext();

    const divRef = useRef<HTMLDivElement | null>(null);

    const [onTheElement, setOnTheElement] = useState(false);

    useStopKeybindPropagation(onTheElement);

    useEffect(() => {
        // Event listener on the document to handle clicks outside the element
        const handleClickOutside = (event: MouseEvent): void => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                // Click outside element
                setOnTheElement(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    /**
     * Handles click on component
     */
    const handleClickOnComponent = (): void => {
        setOnTheElement(!onTheElement);
    };

    return (
        <Paper className={classes.bom}>
            <div ref={divRef} onClick={handleClickOnComponent}>
                <TableContainer component={Paper} id="workInstructionsBomId">
                    <Table stickyHeader={false}>
                        {/* HEADER */}
                        <WorkInstructionsBomHeader />
                        {/* BODY */}
                        <TableBody>
                            {bom?.items ? (
                                <>
                                    {/* BOM STEP ITEMS */}
                                    <WorkInstructionsBomItems
                                        displayStepItems={true}
                                        expandedInitialValue={true}
                                        title={translate("action.workInstructions.bom.itemStepBom")}
                                    />
                                    {/* BOM ITEMS */}
                                    <WorkInstructionsBomItems
                                        displayStepItems={false}
                                        expandedInitialValue={false}
                                        title={translate("action.workInstructions.bom.itemBom")}
                                    />
                                </>
                            ) : (
                                <WorkInstructionsBomReload />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Paper>
    );
}

export default WorkInstructionsBom;
