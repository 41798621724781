import { theme } from "@aos/react-components";
import { IWoBomItem } from "@kortex/aos-common";
import { Tooltip, TooltipProps, makeStyles } from "@material-ui/core";
import React, { FC, ReactElement } from "react";

import { PlayerControlsBomItemInfoTable } from "../itemInfoTable";

const useStyles = makeStyles({
    tableContainer: {
        padding: "6px",
    },
    tooltip: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: "5px",
        minWidth: "500px",
    },
});

export interface IOwnProps {
    children: ReactElement;
    item: IWoBomItem;
    placement?: TooltipProps["placement"];
}

const PlayerControlsBomItemInfoTooltip: FC<IOwnProps> = (props) => {
    const { children, item, placement = "left" } = props;

    const classes = useStyles();

    return (
        <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement={placement}
            title={
                <div className={classes.tableContainer}>
                    <PlayerControlsBomItemInfoTable item={item} />
                </div>
            }
        >
            {children}
        </Tooltip>
    );
};

export default PlayerControlsBomItemInfoTooltip;
