import { theme } from "@aos/react-components";
import { IBomItem, ProcessActionStepWorkInstructions } from "@kortex/aos-common";
import { useProcessEditorContext } from "@kortex/aos-ui/components/pages/ProcessEditor/ProcessEditor/context";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { IconButton, makeStyles, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import React, { useContext } from "react";

import MoveUpIcon from "../../../../../../../../../../core/Icons/MoveUp/MoveUp";
import { ActionEditorContext } from "../../../../../../context";
import { BomItemInfoTooltip } from "../../../utilities";

const useStyles = makeStyles({
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IOwnProps {
    item: IBomItem;
    index: number;
}

function WorkInstructionsBomItemsRow(props: IOwnProps): JSX.Element {
    const { item, index } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const { bom } = useProcessEditorContext();
    const { onChangedStep, editedActionStep } = useContext(ActionEditorContext);

    const assignToStepButtonDisabled = Boolean((editedActionStep as ProcessActionStepWorkInstructions).config.bomItems[item.partNumber]);

    /**
     * Add BOM item to the step
     */
    const handleMoveItemBomToItemStep = (partNumber: string) => (): void => {
        if (editedActionStep && bom) {
            const step = deepClone(editedActionStep as ProcessActionStepWorkInstructions);
            step.config.bomItems = Object.assign(
                { [partNumber]: { partNumber, quantity: item.quantity === 0 ? 0 : 1 } },
                step.config.bomItems
            );

            onChangedStep(step);
        }
    };

    return (
        <TableRow key={index}>
            <TableCell component="th" scope="row" id={`${item.partNumber}BubbleNumberId`}>
                {item.bubbleNumber}
            </TableCell>
            <TableCell id={`${item.partNumber}Id`}>
                <BomItemInfoTooltip item={item}>
                    <Typography variant="body2">{item.partNumber}</Typography>
                </BomItemInfoTooltip>
            </TableCell>
            <TableCell align="right" width={"5px"} />
            <TableCell align="center" width={"10%"} id={`${item.partNumber}QuantityId`}>
                {item.quantity}
            </TableCell>
            <TableCell align="right" width={"5px"} />
            <TableCell align="right" width={"5px"}>
                <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    placement="left"
                    title={<Typography variant="body2">{translate("action.workInstructions.bom.addToStep")}</Typography>}
                >
                    <IconButton
                        disabled={assignToStepButtonDisabled}
                        id={`move${item.partNumber}ItemBomToItemStepButtonId`}
                        onClick={handleMoveItemBomToItemStep(item.partNumber)}
                    >
                        <MoveUpIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}

export default WorkInstructionsBomItemsRow;
