import { KortexTextField, theme } from "@aos/react-components";
import { OrUndefined } from "@kortex/aos-api-client";
import { IBomFollowUp, ISerializedItem, IWoBomItem } from "@kortex/aos-common";
import { MenuType, NonTraceableItemMenu, TraceabilityMenu } from "@kortex/aos-ui/components/core/bom";
import { usePlayerContext } from "@kortex/aos-ui/components/core/ProcessPlayer/context";
import { isBomItemTraceable } from "@kortex/aos-ui/components/pages/bom/woBomTable/utils";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import {
    processPlayerBomFollowUpInsertFollowUp,
    processPlayerBomFollowUpInsertFollowUpSerializedItem,
} from "@kortex/aos-ui/redux/player-manager/player-thunk-bom-followUp";
import {
    IconButton,
    InputAdornment,
    makeStyles,
    PopoverPosition,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { FC, useState } from "react";

import { PlayerControlsBomItemInfoTooltip } from "../../../utilities";

const useStyles = makeStyles({
    cell: {
        borderBottom: "none",
    },
    cellSerialized: {
        flexDirection: "column",
    },
    root: {}, // To overwrite with props
    row: {
        alignItems: "center",
        borderBottom: `0.1px solid ${theme.palette.grey[300]}`,
        display: "grid",
        justifyContent: "center",
        width: "100%",
    },
    rowPartNumber: {
        backgroundColor: "#cccccc",
    },
    rowStandard: {
        gridTemplateColumns: "10% 30% 30% 20% 10%",
    },
    rowTraceable: {
        gridTemplateColumns: "14% 43% 43%",
    },
    editIcon: {
        padding: "0px",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    item: IWoBomItem;
    index: number;
}

const PlayerControlsBomItemRow: FC<IOwnProps> = (props) => {
    const { item, index } = props;

    const classes = useStyles(props);
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const { bomStepItems, jobProcessInfo, playerState, woBom } = usePlayerContext();

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | undefined>(undefined);
    const [menuType, setMenuType] = useState<MenuType>("traceability");
    const [followUpSelected, setFollowUpSelected] = useState<OrUndefined<IBomFollowUp>>(undefined);
    const [serializedItemSelected, setSerializedItemSelected] = useState<ISerializedItem | undefined>(undefined);

    const isItemTraceable = isBomItemTraceable(item);
    const isBomDisabled = !(playerState.processState.bom.enabled && Boolean(jobProcessInfo));
    const processActionStepId = playerState.uiActionProps?.stepProps.processActionStepId;
    const isOptionsButtonDisabled =
        isBomDisabled || !Boolean(woBom) || processActionStepId === undefined || !item.followUp[processActionStepId];
    const quantityMax = bomStepItems[item.partNumber]?.quantity ?? item.quantity;

    const handleMenuClose = (): void => {
        setMenuPosition(undefined);
    };

    const handleDialogClose = (): void => {
        setFollowUpSelected(undefined);
        setSerializedItemSelected(undefined);
    };

    /**
     * Handles on click menu button
     */
    const handleMoreClick =
        (type: MenuType, followUp?: IBomFollowUp, serializedItem?: ISerializedItem): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            if (type === "serial") setSerializedItemSelected(serializedItem);

            setMenuType(type);
            setFollowUpSelected(followUp);
            setMenuPosition({
                left: event.clientX,
                top: event.clientY,
            });
        };

    /**
     * Handles traceability change
     **/
    const handleTraceabilityChange = async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
        const newValue = event.target.value;

        if (!newValue.trim().length) return void 0;

        dispatch(
            processPlayerBomFollowUpInsertFollowUp({
                jobRefId: jobProcessInfo!.job.jobRefId,
                partNumber: item.partNumber,
                processActionStepId,
                quantity: quantityMax,
                traceability: newValue,
                trackingId: playerState.processState.serialNumber,
                trackingInstances: playerState.processState.serialNumberInstances.toString(),
            })
        );
    };

    /**
     * Handles serial number change
     **/
    const handleSerialNumberChange =
        (followUpId: OrUndefined<IBomFollowUp["bomFollowUpId"]>): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            const serialNumber = event.target.value;

            if (!followUpId || !serialNumber) return void 0;

            dispatch(
                processPlayerBomFollowUpInsertFollowUpSerializedItem({
                    bomFollowUpId: followUpId,
                    serialNumber,
                    type: "standard",
                })
            );
        };

    const renderRowsSerialNumber = (qty: number, bomFollowUpLine?: IBomFollowUp): JSX.Element[] => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < qty; i++) {
            const textfieldDisabled =
                isBomDisabled || !Boolean(bomFollowUpLine?.traceability) || Boolean(bomFollowUpLine?.serializedItems[i]?.serialNumber);
            const editIconDisabled =
                isBomDisabled || !Boolean(bomFollowUpLine?.traceability) || !Boolean(bomFollowUpLine?.serializedItems[i]?.serialNumber);

            elements.push(
                <div className={`${classes.cell} ${classes.cellSerialized}`} key={i}>
                    <KortexTextField
                        TextFieldProps={{
                            autoComplete: "off",
                            id: `serialNumberInput${item.partNumber}-${i}Id`,
                            disabled: textfieldDisabled,
                            placeholder: item.serialized ? translate("player.bom.serialNumber") : translate("general.na"),
                        }}
                        onBlur={handleSerialNumberChange(bomFollowUpLine?.bomFollowUpId)}
                        value={bomFollowUpLine?.serializedItems[i]?.serialNumber ?? ""}
                        variant="standard"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={editIconDisabled}
                                        id={`playerControlsBomItemMore${item.partNumber}-${i}Id`}
                                        onClick={handleMoreClick("serial", bomFollowUpLine, bomFollowUpLine?.serializedItems[i])}
                                        className={classes.editIcon}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            );
        }

        return elements;
    };

    const renderRowDetail = (bomFollowUpLine: IBomFollowUp, indexRowDetail: number): JSX.Element => {
        const qty = bomFollowUpLine.quantity;

        return (
            <TableRow className={`${classes.row} ${classes.rowTraceable}`} key={indexRowDetail}>
                {/* QUANTITY */}
                <TableCell className={classes.cell} id={`quantity${indexRowDetail}Id`}>
                    {bomFollowUpLine.isOverconsumption ? `+ ${bomFollowUpLine.quantity}` : `${bomFollowUpLine.quantity} / ${quantityMax}`}
                </TableCell>
                {/* TRACEABILITY */}
                <TableCell rowSpan={item.serialized ? qty : 1} className={classes.cell}>
                    <KortexTextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={isOptionsButtonDisabled}
                                        disableRipple={true}
                                        id={`playerControlsBomItemMore${indexRowDetail}Id`}
                                        onClick={handleMoreClick("traceability", bomFollowUpLine)}
                                        className={classes.editIcon}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        TextFieldProps={{
                            autoComplete: "off",
                            id: `traceabilityInput${indexRowDetail}Id`,
                            disabled: true,
                            placeholder: item.lotSerialType,
                        }}
                        value={bomFollowUpLine.traceability}
                        variant="standard"
                    />
                </TableCell>
                {/* SERIAL NUMBER */}
                <TableCell className={classes.cell}>{item.serialized ? renderRowsSerialNumber(qty, bomFollowUpLine) : null}</TableCell>
            </TableRow>
        );
    };

    const renderRowEmpty = (qtyElements: number): JSX.Element => {
        return (
            <TableRow className={`${classes.row} ${classes.rowTraceable}`} key={`playerControlsBomItemRowEmptyRow${qtyElements}`}>
                {/* QUANTITY */}
                <TableCell className={classes.cell} id={`quantity${index}Id`}>
                    {quantityMax}
                </TableCell>
                {/* TRACEABILITY */}
                <TableCell className={classes.cell}>
                    {isItemTraceable && (
                        <KortexTextField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={isOptionsButtonDisabled}
                                            disableRipple={true}
                                            id={`playerControlsBomItemMore${index}Id`}
                                            onClick={handleMoreClick("traceability")}
                                            className={classes.editIcon}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            TextFieldProps={{
                                autoComplete: "off",
                                id: `traceabilityInput${index}Id`,
                                disabled: isBomDisabled,
                                placeholder: item.lotSerialType,
                            }}
                            onBlur={handleTraceabilityChange}
                            value={""}
                            variant="standard"
                        />
                    )}
                </TableCell>
                {/* SERIAL NUMBER */}
                {item.serialized && (
                    <TableCell className={`${classes.cell} ${classes.cellSerialized}`}>{renderRowsSerialNumber(quantityMax)}</TableCell>
                )}
            </TableRow>
        );
    };

    const renderRows = (): JSX.Element[] => {
        let quantityAccumulator = 0;
        const elements: JSX.Element[] = [];

        if (processActionStepId && item.followUp[processActionStepId]) {
            for (const [index, line] of item.followUp[processActionStepId].entries()) {
                quantityAccumulator += line.quantity;

                if (quantityAccumulator > quantityMax && !line.isOverconsumption && !line.quantity) {
                    break;
                }

                elements.push(renderRowDetail(line, index));
            }
        } else {
            elements.push(renderRowEmpty(elements.length));
        }

        // Return a table body with all the row elements
        return elements;
    };

    return (
        <>
            <Table className={classes.root}>
                <React.Fragment key={index}>
                    {/* HEADER */}
                    <TableHead>
                        <TableRow className={`${classes.row} ${classes.rowPartNumber} ${classes.rowStandard}`}>
                            {/* BUBBLE NUMBER */}
                            <TableCell className={classes.cell}>{`${translate("player.bom.bubbleNumber")} ${
                                item.bubbleNumber ?? ""
                            }`}</TableCell>
                            {/* PART NUMBER */}
                            <PlayerControlsBomItemInfoTooltip item={item} placement="left-end">
                                <TableCell className={classes.cell} colSpan={isItemTraceable ? 2 : 1}>
                                    {item.partNumber}
                                </TableCell>
                            </PlayerControlsBomItemInfoTooltip>
                            {item.lotSerialType.length === 0 ||
                                (!isItemTraceable && (
                                    <>
                                        <TableCell className={classes.cell}>{`${translate("player.bom.quantity")} : ${
                                            item.quantity
                                        }`}</TableCell>
                                        {/*  SUM OF ALL OVERCONSUMPTIONS */}
                                        <TableCell className={classes.cell}>
                                            {processActionStepId && item.followUp[processActionStepId]?.length
                                                ? `+${item.followUp[processActionStepId]
                                                      .map((line) => line.quantity)
                                                      .reduce((acc, value) => acc + value, 0)}`
                                                : ""}
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <IconButton
                                                disabled={isBomDisabled}
                                                disableRipple={true}
                                                id={`playerControlsBomItemMore${index}Id`}
                                                onClick={handleMoreClick("non-traceable")}
                                                className={classes.editIcon}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </>
                                ))}
                        </TableRow>
                        {isItemTraceable && (
                            <TableRow className={`${classes.row} ${classes.rowTraceable}`}>
                                <TableCell className={classes.cell}>{translate("player.bom.quantity")}</TableCell>
                                <TableCell className={classes.cell}>{translate("player.bom.traceability")}</TableCell>
                                {item.serialized && <TableCell className={classes.cell}>{translate("player.bom.serialNumber")}</TableCell>}
                            </TableRow>
                        )}
                    </TableHead>

                    {/* BODY */}
                    {isItemTraceable && <TableBody>{renderRows()}</TableBody>}
                </React.Fragment>
            </Table>
            {!isOptionsButtonDisabled && followUpSelected ? (
                <TraceabilityMenu
                    followUp={followUpSelected}
                    onDialogClose={handleDialogClose}
                    onMenuClose={handleMenuClose}
                    type={menuType}
                    menuPosition={menuPosition}
                    serializedItem={serializedItemSelected}
                    variant="player"
                />
            ) : null}
            {!isItemTraceable && woBom ? (
                <NonTraceableItemMenu
                    item={item}
                    menuPosition={menuPosition}
                    onDialogClose={handleDialogClose}
                    onMenuClose={handleMenuClose}
                    processActionStepId={processActionStepId}
                    variant="player"
                    woBom={woBom}
                />
            ) : null}
        </>
    );
};

export default PlayerControlsBomItemRow;
