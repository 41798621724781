import { theme } from "@aos/react-components";
import { IBomItem, ProcessActionStepWorkInstructions } from "@kortex/aos-common";
import SerializedItemIcon from "@kortex/aos-ui/components/core/Icons/serializedItem/serializedItem";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { IconButton, makeStyles, Menu, MenuItem, PopoverPosition, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useContext, useState } from "react";

import { useProcessEditorContext } from "../../../../../../../context";
import { ActionEditorContext } from "../../../../../../context";
import { BomItemInfoTooltip } from "../../../utilities";

const useStyles = makeStyles({
    partNumberContainer: {
        alignItems: "center",
        display: "flex",
    },
    serializedItemIcon: {
        marginLeft: "10px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IOwnProps {
    item: IBomItem;
    index: number;
}

function WorkInstructionsBomStepItemsRow(props: IOwnProps): JSX.Element {
    const { item, index } = props;

    const classes = useStyles();
    const actionEditorContext = useContext(ActionEditorContext);
    const { bom } = useProcessEditorContext();
    const translate = useTranslate();

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | null>(null);

    const addDisabled = !bom?.items?.[item.partNumber] || item.quantity >= bom.items[item.partNumber].quantity;
    const editedActionStep = actionEditorContext.editedActionStep as ProcessActionStepWorkInstructions;
    const removeDisabled = item.quantity - 1 <= 0;
    const serialized = Boolean(editedActionStep?.config.bomItems[item.partNumber]?.serialized);

    const handleDeleteItem = (): void => {
        const step = deepClone(editedActionStep);
        delete step.config.bomItems[item.partNumber];

        handleMenuClose();
        actionEditorContext.onChangedStep(step);
    };

    const handleMenuClose = (): void => {
        setMenuPosition(null);
    };

    const handleMoreClick = (event: React.MouseEvent<HTMLElement>): void => {
        setMenuPosition({
            left: event.clientX,
            top: event.clientY,
        });
    };

    const handleQuantityIncrease =
        (item: IBomItem): (() => void) =>
        (): void => {
            const bomItem = bom?.items?.[item.partNumber];
            const updatedQuantity = item.quantity + 1;

            if (bomItem && updatedQuantity <= bomItem.quantity) {
                const step = deepClone(editedActionStep);
                step.config.bomItems[item.partNumber].quantity = updatedQuantity;

                actionEditorContext.onChangedStep(step);
            }
        };

    const handleQuantityDecrease =
        (item: IBomItem): (() => void) =>
        (): void => {
            const updatedQuantity = item.quantity - 1;

            const step = deepClone(editedActionStep);
            step.config.bomItems[item.partNumber].quantity = updatedQuantity < 0 ? 0 : updatedQuantity; // Make sure quantity is not below 0

            actionEditorContext.onChangedStep(step);
        };

    const handleSerializeItem = (): void => {
        const step = deepClone(editedActionStep);
        step.config.bomItems[item.partNumber].serialized = !step.config.bomItems[item.partNumber].serialized;

        handleMenuClose();
        actionEditorContext.onChangedStep(step);
    };

    return (
        <>
            <TableRow key={index}>
                <TableCell component="th" scope="row" id={`${item.partNumber}BubbleNumberId`}>
                    {item.bubbleNumber}
                </TableCell>
                <TableCell id={`${item.partNumber}Id`}>
                    <BomItemInfoTooltip item={item} key={index}>
                        <div className={classes.partNumberContainer}>
                            <Typography variant="body2">{item.partNumber}</Typography>
                            {serialized ? (
                                <Tooltip
                                    classes={{ tooltip: classes.tooltip }}
                                    placement="right"
                                    title={translate("player.bom.itemSerialized")}
                                >
                                    <div className={classes.serializedItemIcon}>
                                        <SerializedItemIcon height={24} width={24} />
                                    </div>
                                </Tooltip>
                            ) : null}
                        </div>
                    </BomItemInfoTooltip>
                </TableCell>
                <TableCell align="right" width={"5px"} id={`${item.partNumber}AddQtyId`}>
                    <IconButton id={`${item.partNumber}addQtyButtonId`} disabled={addDisabled} onClick={handleQuantityIncrease(item)}>
                        <AddIcon fontSize="small" />
                    </IconButton>
                </TableCell>
                <TableCell align="center" width={"10%"} id={`${item.partNumber}QuantityId`}>
                    {item.quantity}
                </TableCell>
                <TableCell align="left" width={"5px"}>
                    <IconButton id={`${item.partNumber}RemoveQtyButtonId`} disabled={removeDisabled} onClick={handleQuantityDecrease(item)}>
                        <RemoveIcon fontSize="small" />
                    </IconButton>
                </TableCell>
                <TableCell align="right" width={"5px"}>
                    <IconButton onClick={handleMoreClick}>
                        <MoreVertIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <Menu
                anchorPosition={menuPosition ?? undefined}
                anchorReference="anchorPosition"
                open={menuPosition !== null}
                onClose={handleMenuClose}
                id="itemStepMenuId"
            >
                <MenuItem disabled={item.quantity <= 0} onClick={handleSerializeItem}>
                    {serialized ? translate("action.workInstructions.bom.deserialize") : translate("action.workInstructions.bom.serialize")}
                </MenuItem>
                <MenuItem onClick={handleDeleteItem}>{translate("general.delete")}</MenuItem>
            </Menu>
        </>
    );
}

export default WorkInstructionsBomStepItemsRow;
