import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { InputAdornment, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";

import { TextFieldSelect } from "../../../../../../../core/TextFieldSelect";
import { useProcessEditorContext } from "../../../../context";

import { useWorkInstructionBomContext } from "./../context";

const useStyles = makeStyles({
    textField: {
        height: "100%",
        width: "100%",
    },
    input: {
        padding: "8px 8px",
    },
});

function WorkInstructionsBomHeader(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    const { bom } = useProcessEditorContext();
    const { setSearchValue } = useWorkInstructionBomContext();

    const [itemTextFieldSelect, setItemTextFieldSelect] = useState<string[]>([]);

    useEffect((): void => {
        if (bom?.items) {
            const items = deepClone(itemTextFieldSelect);

            for (const item of Object.values(bom.items)) {
                items.push(item.partNumber);
                items.push(item.description);
            }

            setItemTextFieldSelect(items.sort());
        }
    }, [bom]);

    /**
     * Handles search change
     *
     * @param {string} value - value
     */
    const handleSearchChange = (value: string): void => {
        setSearchValue(value);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell width={"20%"}>
                    <Typography variant={"h2"} align={"center"}>
                        {translate("action.workInstructions.bom.bom")}
                    </Typography>
                </TableCell>
                <TableCell>
                    <TextFieldSelect
                        KortexTextFieldProps={{
                            label: "",
                            TextFieldProps: {
                                className: classes.textField,
                                autoFocus: false,
                                id: "itemInputId",
                            },
                            InputProps: {
                                classes: {
                                    input: classes.input,
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            },
                        }}
                        initialValue={""}
                        items={itemTextFieldSelect.map((item) => {
                            return {
                                component: <Typography>{item}</Typography>,
                                filterCallback: (value: string): boolean =>
                                    value ? item.toLocaleLowerCase().includes(value.toLocaleLowerCase()) : true,
                                textFieldValue: item,
                                value: item,
                            };
                        })}
                        onChange={handleSearchChange}
                        showClearButton={true}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default WorkInstructionsBomHeader;
