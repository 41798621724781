import { theme } from "@aos/react-components";
import { IWoBomItem } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles({
    tableCell: {
        border: `1px solid ${theme.palette.common.black}`,
        padding: "12px",
    },
    tableRowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    tableRowHeader: {
        backgroundColor: theme.palette.grey[300],
    },
    tableRowOdd: {
        backgroundColor: theme.palette.common.white,
    },
});

export interface IOwnProps {
    item: IWoBomItem;
}

const BomItemInfoTable: FC<IOwnProps> = (props) => {
    const { item } = props;
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableRowHeader}>
                    <TableCell className={classes.tableCell} width="33%">
                        <Typography variant="h6">{translate("action.workInstructions.bom.information")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} width="67%">
                        <Typography variant="h6">{translate("action.workInstructions.bom.value")}</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={classes.tableRowOdd}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("player.bom.bubbleNumber")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{item.bubbleNumber}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.tableRowEven}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("player.bom.partNumber")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{item.partNumber}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.tableRowOdd}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("player.bom.description")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{item.description}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.tableRowEven}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("player.bom.quantity")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{item.quantity}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.tableRowOdd}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("player.bom.unit")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{item.unit}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.tableRowEven}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("player.bom.location")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{item.location}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.tableRowOdd}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("player.bom.materialLocation")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{item.materialLocation}</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default BomItemInfoTable;
